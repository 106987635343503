<template>
  <v-row>
    <v-col lg="12" cols="12">
      <v-breadcrumbs v-if="edit_pool" :items="[
        {
          text: 'Управление пулами',
          disabled: false,
          href: '/#/admin/opd',
        },
        {
          text: edit_pool.name,
          disabled: true,
        }]"></v-breadcrumbs>
    </v-col>
    <v-col lg="6" md="12">
      <CardUI v-if="edit_pool">
        <template v-slot:header>
          <h4>
            #{{ edit_pool.id }} {{ edit_pool.name }}
          </h4>
        </template>
        <template v-slot:body>
          <div class="mt-2" style="width: 100%">
            <h5>Название пула</h5>
            <InputUI v-model="edit_pool.name" placeholder="Название пула"/>
          </div>
          <div>
            <h5>Период пула</h5>
            <DatePickerUIDecorator>
              <DatePicker format="DD.MM.YYYY"
                          :range=true
                          :popup-style="{left: 0, top:32, position: 'absolute'}"
                          value-type="YYYY-MM-DD"
                          :show-second="false"
                          :append-to-body="false"
                          :value="[edit_pool.start_at, edit_pool.end_at]" input-class="date-input"
                          @input="edit_pool.start_at =  $event[0]; edit_pool.end_at =  $event[1];"
                          placeholder="Период регистрации"
                          type="date"></DatePicker>
            </DatePickerUIDecorator>
          </div>
          <div class="mt-2">
            <h5>Максимальное кол-во проектов у студента</h5>
            <InputUI v-model="edit_pool.max_projects" placeholder="1"/>
          </div>
          <div>
            <v-switch v-model="edit_pool.can_create_project" label="Студент может создавать проекты"/>
          </div>
          <div>
            <h5>Дата до которой можно вступить в проект</h5>
            <DatePickerUIDecorator>
              <DatePicker format="DD.MM.YYYY"
                          value-type="YYYY-MM-DD"
                          :popup-style="{left: 0, top:32, position: 'absolute'}"
                          :show-second="false"
                          :append-to-body="false"
                          v-model="edit_pool.join_before" input-class="date-input"
                          placeholder="Дедлайн вступления"
                          type="date"></DatePicker>
            </DatePickerUIDecorator>
          </div>


          <div>
            <h5>Дата до которой можно выйти из проекта</h5>
            <DatePickerUIDecorator>
              <DatePicker format="DD.MM.YYYY"
                          :popup-style="{left: 0, top:32, position: 'absolute'}"
                          value-type="YYYY-MM-DD"
                          :append-to-body="false"
                          :show-second="false"
                          v-model="edit_pool.leave_before" input-class="date-input"
                          placeholder="Дедлайн смены проекта"
                          type="date"></DatePicker>
            </DatePickerUIDecorator>
          </div>
          <div class="mt-4">
            <h4>Группы в пуле</h4>
            <div>
              <v-autocomplete v-model="edit_pool.student_group_ids" chips outlined
                              placeholder="Начните печатать название группы" no-data-text="Не найдено" item-text="name"
                              item-value="id"
                              multiple :items="$store.state.dictionaries.studentGroups"/>

            </div>
          </div>
          <v-btn :loading="loading" @click="store" class="mb-4" outlined color="green" rounded>Сохранить</v-btn>
        </template>
      </CardUI>
      <CardUI>
        <template v-slot:header>
          <h4>Отчеты</h4>
        </template>
        <template v-slot:body>
          <div class="mt-4 d-flex">
            <div>
              Информация о проектах и участниках
            </div>
            <div>
              <v-btn :loading="reportLoading" @click="getReport" icon color="primary">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </CardUI>
    </v-col>
    <v-col lg="6">
      <CardUI v-if="edit_pool">
        <template v-slot:header>
          <v-tabs v-model="chat_tab" background-color="transparent">
            <v-tab>Чат с проектами</v-tab>
            <v-tab>Чат без проектов</v-tab>
          </v-tabs>
        </template>
        <template v-slot:body>
          <div style="height: 500px;">
            <SmartChatComponent v-if="edit_pool.without_project_chat_id && edit_pool.all_chat_id"
                                :chat_id="chat_tab ? edit_pool.without_project_chat_id : edit_pool.all_chat_id"/>
          </div>

        </template>
      </CardUI>
      <CardUI v-if="edit_pool && problems">
        <template v-slot:header>
          <h4>Проблемы в пуле</h4>
        </template>
        <template v-slot:body>
          <v-row>
            <v-col v-for="problem in problems" :key="problem.problem" sm="6" cols="12">
              <v-card style="border-color: rgba(243,0,19,0.29)" outlined>
                <v-card-text class="">
                  <div class="title black--text">
                    {{problem.problem}}
                  </div>
                  <div>
                    <span>
                        {{problem.count}} / {{problem.target}}
                    </span>
                    <v-btn style="float: right" x-small outlined color="error" :href="problem.example">Пример</v-btn>
                  </div>
                </v-card-text>

              </v-card>
            </v-col>
          </v-row>
        </template>
      </CardUI>
      <CardUI class="mb-4">
        <template v-slot:header>
          Задачи
          <v-badge
              color="primary"
              dot
              inline
          ></v-badge>
          <v-spacer/>
          <v-dialog v-if="edit_pool" v-model="createPresetModalShow" max-width="500">
            <CreatePresetModal :pool_id="edit_pool.id" @close="createPresetModalShow = false; getPresets();"/>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" outlined color="green" small rounded>Добавить задачу</v-btn>
            </template>
          </v-dialog>

        </template>
        <template v-slot:body>
          <Preset @changed="getPresets" :preset="preset" class="mt-2" style="background:#f8f8f8; padding: 10px; border-radius: 10px" v-for="preset in presets"
               :key="preset.id"/>
        </template>
      </CardUI>

    </v-col>
  </v-row>

</template>

<script>
import DatePicker from 'vue2-datepicker';
import plural from "@/plural.js";
import CardUI from "@/components/UI/CardUI.vue";
import DatePickerUIDecorator from "@/components/UI/DatePickerUIDecorator.vue";
import InputUI from "@/components/UI/InputUI.vue";
import project_pools from "@/api/project_pools";
import Preset from "@/components/Admin/ProjectPools/Preset";
import CreatePresetModal from "@/components/Admin/ProjectPools/CreatePresetModal";
import SmartChatComponent from "@/components/Messages/SmartChatComponent";

export default {
  name: "ProjectPool",
  components: {SmartChatComponent, CreatePresetModal, Preset, CardUI, DatePickerUIDecorator, DatePicker, InputUI},
  data() {
    return {
      plural: plural,
      loading: false,
      reportLoading: false,
      showAddGroupModal: true,
      createPresetModalShow: false,
      edit_pool: null,
      presets: [],
      problems: [],
      chat_tab: null,
      problemsLoading: false,
    }
  },
  methods: {
    getReport() {
      this.reportLoading = true;
      project_pools.getReport({id: this.edit_pool.id}).then(r => {
        const path = window.URL.createObjectURL(new Blob(['\ufeff' + r.data]));
        const link = document.createElement('a');
        link.href = path;
        link.setAttribute('download', 'report.csv');
        document.body.appendChild(link);
        link.click();
      }).finally(() => {
        this.reportLoading = false;
      })
    },

    getProblems() {
      this.problemsLoading = true;
      project_pools.getProblems({id: this.edit_pool.id}).then(r => {
          this.problems = r.data;
      }).finally(() => {
        this.problemsLoading = false;
      })
    },
    store() {
      this.loading = true;
      this.$store.dispatch('saveProjectPool', this.edit_pool).finally(() => {
        this.loading = false;
      })
    },
    getPresets(){
      project_pools.getPresets({id: this.$route.params.id}).then((r) => {
        this.presets = r.data;
      })
    },
    removeGroupFromPool(g_id) {
      this.loading = true;
      this.$store.dispatch('removeGroupFromPool', {id: this.edit_pool.id, student_group_id: g_id}).finally(() => {
        this.loading = false;
      })
    }
  },
  mounted() {
    if (this.$route.params.id > 0) {
      project_pools.item({id: this.$route.params.id}).then((r) => {
        this.$emit('changeTitle', r.data.name);
        this.edit_pool = {...r.data, student_group_ids: r.data.student_groups.map(sg => sg.id)};
        this.getProblems();
      });

      this.getPresets();

    }

  }
}
</script>

<style scoped>

</style>
