<template>
  <div class="mt-2" style="background:#f8f8f8; padding: 10px; border-radius: 10px"
       :key="preset.id">
    <h4>
      #{{ preset.id }} {{ preset.code }}
    </h4>
    <div>
      {{ preset.description }}
    </div>
    <div class="my-2">
      <v-chip>
        Начало {{ $moment.utc(preset.create_after_at).format('DD.MM.YYYY HH:mm') }}
      </v-chip>
      <v-chip class="ml-2">
        Дедлайн {{ $moment.utc(preset.deadline_at).format('DD.MM.YYYY HH:mm') }}
      </v-chip>
    </div>
    <h5>Прикрепленные файлы</h5>
    <FileUpload multiple :read-only="true"
                :value="preset.files"/>
    <div>
      <v-btn :loading="loading" rounded @click="deployPreset" :disabled="!!preset.deployed" class="ml-2 mt-2" small outlined color="green">Создать во всех проектах</v-btn>
    </div>
  </div>
</template>

<script>
import FileUpload from "@/components/Common/FileUpload";
import project_pools from "@/api/project_pools";

export default {
  name: "Preset",
  props: ['preset'],
  components: {FileUpload},
  data(){
    return {
      loading: false,
    }
  },
  methods: {
    deployPreset(){
      this.loading = true;
      project_pools.deployPreset({id: this.preset.id}).then(() => {
        this.$emit('changed');
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>
