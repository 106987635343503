<template>
  <v-card>
    <v-card-title>
      Создать задачу
    </v-card-title>
    <v-card-text>
      <v-text-field v-model="code" label="Название задачи (для себя)" outlined placeholder="Отчет 1"/>
      <v-textarea v-model="description" label="Описание задачи" outlined
                  placeholder="Cоставьте отчет по шаблону в прикрепленном файле"/>
      <h4 class="mt-2">Начало выполнения</h4>
      <DatePickerUIDecorator>
        <DatePicker value-type="YYYY-MM-DD HH:mm" format="DD.MM HH:mm"
                    :show-second="false" placeholder="Срок"
                    type="datetime" input-class="date-input"
                    v-model="created_after_at"></DatePicker>
      </DatePickerUIDecorator>

      <h4 class="mt-2">Дедлайн</h4>
      <DatePickerUIDecorator>
        <DatePicker value-type="YYYY-MM-DD HH:mm" format="DD.MM HH:mm"
                    :show-second="false" placeholder="Дедлайн"
                    type="datetime" input-class="date-input"
                    v-model="deadline_at"></DatePicker>
      </DatePickerUIDecorator>

      <h4>Файлы (будут доступны студентам)</h4>
      <FileUpload multiple
                  v-model="files"/>

    </v-card-text>
    <v-card-actions>
      <v-btn @click="() => {create(); $emit('close');}" outlined rounded color="green">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import DatePickerUIDecorator from "@/components/UI/DatePickerUIDecorator";
import DatePicker from 'vue2-datepicker';
import FileUpload from "@/components/Common/FileUpload";
import project_pools from "@/api/project_pools";

export default {
  name: "CreatePresetModal",
  props: ['pool_id'],
  components: {DatePickerUIDecorator, DatePicker, FileUpload},
  data() {
    return {
      description: null,
      deadline_at: null,
      created_after_at: null,
      code: null,
      files: []
    }
  },
  methods: {
    create(){
      project_pools.createPreset({
        description: this.description,
        deadline_at: this.deadline_at,
        created_after_at: this.created_after_at,
        code: this.code,
        id: this.pool_id,
        files: this.files.map((f) => f.id)
      })
    }
  }
}
</script>

<style scoped>

</style>
